<template>
  <div>
    <div class="mb-8">
      <section class="text-gray-700">
        <div class="container px-5 py-24 mx-auto">
          <div class="text-center mb-20">
            <h1
              class="
                sm:text-3xl
                text-2xl
                font-medium
                text-center
                title-font
                text-gray-900
                mb-4
              "
            >
              Privacy Policy
            </h1>
          </div>
        </div>
      </section>
      <div class="container leading-relaxed mx-auto">
        <h3 class="title-font text-bold-600 mb-4 text-2xl">PRIVACY NOTICE</h3>
        <p class="text-base">Last updated February 14, 2021</p>
        <p class="text-base">
          Thank you for choosing to be part of our community at ALPHASOFTHUB
          Private Limited (“Company“, “we“, “us“, “our“). We are committed to
          protecting your personal information and your right to privacy. If you
          have any questions or concerns about this privacy notice, or our
          practices with regards to your personal information, please contact us
          at hello@alphasofthub.com.
        </p>
        <p class="text-base">
          When you visit our website http://alphasofthub.com/ (the “Website“),
          and more generally, use any of our services (the “Services“, which
          include the Website), we appreciate that you are trusting us with your
          personal information. We take your privacy very seriously. In this
          privacy notice, we seek to explain to you in the clearest way possible
          what information we collect, how we use it and what rights you have in
          relation to it. We hope you take some time to read through it
          carefully, as it is important. If there are any terms in this privacy
          notice that you do not agree with, please discontinue use of our
          Services immediately.
        </p>
        <p class="text-base">
          This privacy notice applies to all information collected through our
          Services (which, as described above, includes our Website), as well
          as, any related services, sales, marketing or events.
        </p>
        <p class="text-base">
          Please read this privacy notice carefully as it will help you
          understand what we do with the information that we collect.
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          TABLE OF CONTENTS
        </h3>
        <li>1. WHAT INFORMATION DO WE COLLECT?</li>
        <li>2. HOW DO WE USE YOUR INFORMATION?</li>
        <li>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
        <li>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
        <li>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
        <li>6. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</li>
        <li>7. HOW LONG DO WE KEEP YOUR INFORMATION?</li>
        <li>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
        <li>9. WHAT ARE YOUR PRIVACY RIGHTS?</li>
        <li>10. CONTROLS FOR DO-NOT-TRACK FEATURES</li>
        <li>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
        <li>12. DO WE MAKE UPDATES TO THIS NOTICE?</li>
        <li>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
        <li>
          14. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?
        </li>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          1. WHAT INFORMATION DO WE COLLECT?
        </h3>
        <p class="text-base">Personal information you disclose to us</p>
        <p class="text-base">
          In Short: We collect personal information that you provide to us.
        </p>
        <p class="text-base">
          We collect personal information that you voluntarily provide to us
          when you register on the Website, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Website or otherwise when you contact
          us.
        </p>
        <p class="text-base">
          The personal information that we collect depends on the context of
          your interactions with us and the Website, the choices you make and
          the products and features you use. The personal information we collect
          may include the following:
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          Personal Information Provided by You.
        </h3>
        <p class="text-base">
          We collect names; phone numbers; email addresses; mailing addresses;
          job titles; usernames; passwords; contact preferences; contact or
          authentication data; billing addresses; and other similar information.
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">Payment Data</h3>
        <p class="text-base">
          We may collect data necessary to process your payment if you make
          purchases, such as your payment instrument number (such as a credit
          card number), and the security code associated with your payment
          instrument. All payment data is stored by2Checkout. You may find their
          privacy notice link(s) here:https://www.2checkout.com/legal/privacy/.
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          Social Media Login Data.
        </h3>
        <p class="text-base">
          We may provide you with the option to register with us using your
          existing social media account details, like your Facebook, Twitter or
          other social media account. If you choose to register in this way, we
          will collect the information described in the section called “HOW DO
          WE HANDLE YOUR SOCIAL LOGINS” below.
        </p>
        <p class="text-base">
          All personal information that you provide to us must be true, complete
          and accurate, and you must notify us of any changes to such personal
          information.
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          Information automatically collected
        </h3>
        <h3 class="title-font text-bold-600 mb-4 text-2xl">In Short:</h3>
        <p class="text-base">
          Some information — such as your Internet Protocol (IP) address and/or
          browser and device characteristics — is collected automatically when
          you visit our Website.
        </p>
        <p class="text-base">
          We automatically collect certain information when you visit, use or
          navigate the Website. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Website and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Website, and for our internal analytics and reporting purposes.
        </p>
        <p class="text-base">
          Like many businesses, we also collect information through cookies and
          similar technologies. You can find out more about this in our Cookie
          Notice: https://alphasofthub.com/index.php/cookie-policy/.
        </p>
        <p class="text-base">The information we collect includes:</p>
        <li>
          Log and Usage Data. Log and usage data is service-related, diagnostic,
          usage and performance information our servers automatically collect
          when you access or use our Website and which we record in log files.
          Depending on how you interact with us, this log data may include your
          IP address, device information, browser type and settings and
          information about your activity in the Website (such as the date/time
          stamps associated with your usage, pages and files viewed, searches
          and other actions you take such as which features you use), device
          event information (such as system activity, error reports (sometimes
          called ‘crash dumps’) and hardware settings).
        </li>
        <li>
          Device Data. We collect device data such as information about your
          computer, phone, tablet or other device you use to access the Website.
          Depending on the device used, this device data may include information
          such as your IP address (or proxy server), device and application
          identification numbers, location, browser type, hardware model
          Internet service provider and/or mobile carrier, operating system and
          system configuration information.
        </li>
        <li>
          Location Data. We collect location data such as information about your
          device’s location, which can be either precise or imprecise. How much
          information we collect depends on the type and settings of the device
          you use to access the Website. For example, we may use GPS and other
          technologies to collect geolocation data that tells us your current
          location (based on your IP address). You can opt out of allowing us to
          collect this information either by refusing access to the information
          or by disabling your Location setting on your device. Note however, if
          you choose to opt out, you may not be able to use certain aspects of
          the Services.
        </li>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          2. HOW DO WE USE YOUR INFORMATION?
        </h3>
        <h3 class="title-font text-bold-600 mb-4 text-2xl">In Short:</h3>
        <p class="text-base">
          We process your information for purposes based on legitimate business
          interests, the fulfillment of our contract with you, compliance with
          our legal obligations, and/or your consent.
        </p>
        <p class="text-base">
          We use personal information collected via our Website for a variety of
          business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations. We
          indicate the specific processing grounds we rely on next to each
          purpose listed below.
        </p>
        <p class="text-base">We use the information we collect or receive:</p>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            To facilitate account creation and logon process.
          </h3>
          <p class="text-base">
            If you choose to link your account with us to a third-party account
            (such as your Google or Facebook account), we use the information
            you allowed us to collect from those third parties to facilitate
            account creation and logon process for the performance of the
            contract. See the section below headed “HOW DO WE HANDLE YOUR SOCIAL
            LOGINS” for further information.
          </p>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            To post testimonials.
          </h3>
          <p class="text-base">
            We post testimonials on our Website that may contain personal
            information. Prior to posting a testimonial, we will obtain your
            consent to use your name and the content of the testimonial. If you
            wish to update, or delete your testimonial, please contact us at
            hello@alphasosfthub.com and be sure to include your name,
            testimonial location, and contact information.
          </p>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            Request feedback.
          </h3>
          <p class="text-base">
            We may use your information to request feedback and to contact you
            about your use of our Website.
          </p>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            To enable user-to-user communications.
          </h3>
          <p class="text-base">
            We may use your information in order to enable user-to-user
            communications with each user’s consent.
          </p>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            To manage user accounts
          </h3>
          <p class="text-base">
            We may use your information for the purposes of managing our account
            and keeping it in working order.
          </p>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            To send administrative information to you.
          </h3>
          <p class="text-base">
            We may use your personal information to send you product, service
            and new feature information and/or information about changes to our
            terms, conditions, and policies.
          </p>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            To protect our Services
          </h3>
          <p class="text-base">
            We may use your information as part of our efforts to keep our
            Website safe and secure (for example, for fraud monitoring and
            prevention).
          </p>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            To enforce our terms, conditions and policies for business purposes,
            to comply with legal and regulatory requirements or in connection
            with our contract.
          </h3>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            To respond to legal requests and prevent harm.
          </h3>
          <p class="text-base">
            If we receive a subpoena or other legal request, we may need to
            inspect the data we hold to determine how to respond.
          </p>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            Fulfill and manage your orders
          </h3>
          <p class="text-base">
            We may use your information to fulfill and manage your orders,
            payments, returns, and exchanges made through the Website.
          </p>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            Administer prize draws and competitions
          </h3>
          <p class="text-base">
            We may use your information to administer prize draws and
            competitions when you elect to participate in our competitions.
          </p>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            To deliver and facilitate delivery of services to the user.
          </h3>
          <p class="text-base">
            We may use your information to provide you with the requested
            service.
          </p>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            To respond to user inquiries/offer support to users
          </h3>
          <p class="text-base">
            We may use your information to respond to your inquiries and solve
            any potential issues you might have with the use of our Services.
          </p>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            To send you marketing and promotional communications
          </h3>
          <p class="text-base">
            We and/or our third-party marketing partners may use the personal
            information you send to us for our marketing purposes, if this is in
            accordance with your marketing preferences. For example, when
            expressing an interest in obtaining information about us or our
            Website, subscribing to marketing or otherwise contacting us, we
            will collect personal information from you. You can opt-out of our
            marketing emails at any time (see the “WHAT ARE YOUR PRIVACY RIGHTS”
            below).
          </p>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            Deliver targeted advertising to you.
          </h3>
          <p class="text-base">
            We may use your information to develop and display personalized
            content and advertising (and work with third parties who do so)
            tailored to your interests and/or location and to measure its
            effectiveness. For more information see our Cookie Notice:
            https://alphasofthub.com/index.php/cookie-policy/.
          </p>
        </li>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
        </h3>
        <h3 class="title-font text-bold-600 mb-4 text-2xl">In Short:</h3>
        <p class="text-base">
          We only share information with your consent, to comply with laws, to
          provide you with services, to protect your rights, or to fulfill
          business obligations.
        </p>
        <p class="text-base">
          We may process or share your data that we hold based on the following
          legal basis:
        </p>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">Consent</h3>
          <p class="text-base">
            We may process your data if you have given us specific consent to
            use your personal information for a specific purpose.
          </p>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            Legitimate Interests:
          </h3>
          <p class="text-base">
            We may process your data when it is reasonably necessary to achieve
            our legitimate business interests.
          </p>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            Performance of a Contract:
          </h3>
          <p class="text-base">
            Where we have entered into a contract with you, we may process your
            personal information to fulfill the terms of our contract.
          </p>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            Legal Obligations:
          </h3>
          <p class="text-base">
            We may disclose your information where we are legally required to do
            so in order to comply with applicable law, governmental requests, a
            judicial proceeding, court order, or legal process, such as in
            response to a court order or a subpoena (including in response to
            public authorities to meet national security or law enforcement
            requirements)
          </p>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            Vital Interests
          </h3>
          <p class="text-base">
            We may disclose your information where we believe it is necessary to
            investigate, prevent, or take action regarding potential violations
            of our policies, suspected fraud, situations involving potential
            threats to the safety of any person and illegal activities, or as
            evidence in litigation in which we are involved.
          </p>
        </li>
        <p class="text-base">
          More specifically, we may need to process your data or share your
          personal information in the following situations:
        </p>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            Business Transfers.
          </h3>
          <p class="text-base">
            We may share or transfer your information in connection with, or
            during negotiations of, any merger, sale of company assets,
            financing, or acquisition of all or a portion of our business to
            another company.
          </p>
        </li>
        <li>
          <h3 class="title-font text-bold-600 mb-4 text-2xl">
            Business Partners.
          </h3>
          <p class="text-base">
            We may share your information with our business partners to offer
            you certain products, services or promotions.
          </p>
        </li>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </h3>
        <h3 class="title-font text-bold-600 mb-4 text-2xl">In Short:</h3>
        <p class="text-base">
          We may use cookies and other tracking technologies to collect and
          store your information.
        </p>
        <p class="text-base">
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice:
          https://alphasofthub.com/index.php/cookie-policy/.
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        </h3>
        <h3 class="title-font text-bold-600 mb-4 text-2xl">In Short:</h3>
        <p class="text-base">
          If you choose to register or log in to our services using a social
          media account, we may have access to certain information about you.
        </p>

        <p class="text-base">
          Our Website offers you the ability to register and login using your
          third-party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          friends list, profile picture as well as other information you choose
          to make public on such social media platform.
        </p>
        <p class="text-base">
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Website. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          6. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
        </h3>
        <h3 class="title-font text-bold-600 mb-4 text-2xl">In Short:</h3>
        <p class="text-base">
          We are not responsible for the safety of any information that you
          share with third-party providers who advertise, but are not affiliated
          with, our Website.
        </p>
        <p class="text-base">
          The Website may contain advertisements from third parties that are not
          affiliated with us and which may link to other websites, online
          services or mobile applications. We cannot guarantee the safety and
          privacy of data you provide to any third parties. Any data collected
          by third parties is not covered by this privacy notice. We are not
          responsible for the content or privacy and security practices and
          policies of any third parties, including other websites, services or
          applications that may be linked to or from the Website. You should
          review the policies of such third parties and contact them directly to
          respond to your questions.
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          7. HOW LONG DO WE KEEP YOUR INFORMATION?
        </h3>
        <h3 class="title-font text-bold-600 mb-4 text-2xl">In Short:</h3>
        <p class="text-base">
          We keep your information for as long as necessary to fulfill the
          purposes outlined in this privacy notice unless otherwise required by
          law.
        </p>
        <p class="text-base">
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </p>
        <p class="text-base">
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          8. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </h3>
        <h3 class="title-font text-bold-600 mb-4 text-2xl">In Short:</h3>
        <p class="text-base">
          We aim to protect your personal information through a system of
          organizational and technical security measures.
        </p>
        <p class="text-base">
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any personal information
          we process. However, despite our safeguards and efforts to secure your
          information, no electronic transmission over the Internet or
          information storage technology can be guaranteed to be 100% secure, so
          we cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorized third parties will not be able to defeat our security,
          and improperly collect, access, steal, or modify your information.
          Although we will do our best to protect your personal information,
          transmission of personal information to and from our Website is at
          your own risk. You should only access the Website within a secure
          environment.
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          9. WHAT ARE YOUR PRIVACY RIGHTS?
        </h3>
        <h3 class="title-font text-bold-600 mb-4 text-2xl">In Short:</h3>
        <p class="text-base">
          In some regions, such as the European Economic Area, you have rights
          that allow you greater access to and control over your personal
          information. You may review, change, or terminate your account at any
          time.
        </p>
        <p class="text-base">
          In some regions (like the European Economic Area), you have certain
          rights under applicable data protection laws. These may include the
          right (i) to request access and obtain a copy of your personal
          information, (ii) to request rectification or erasure; (iii) to
          restrict the processing of your personal information; and (iv) if
          applicable, to data portability. In certain circumstances, you may
          also have the right to object to the processing of your personal
          information. To make such a request, please use the contact details
          provided below. We will consider and act upon any request in
          accordance with applicable data protection laws.
        </p>
        <p class="text-base">
          If we are relying on your consent to process your personal
          information, you have the right to withdraw your consent at any time.
          Please note however that this will not affect the lawfulness of the
          processing before its withdrawal, nor will it affect the processing of
          your personal information conducted in reliance on lawful processing
          grounds other than consent.
        </p>
        <p class="text-base">
          If you are a resident in the European Economic Area and you believe we
          are unlawfully processing your personal information, you also have the
          right to complain to your local data protection supervisory authority.
          You can find their contact details here:
          http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
        </p>
        <p class="text-base">
          If you are a resident in Switzerland, the contact details for the data
          protection authorities are available here:
          https://www.edoeb.admin.ch/edoeb/en/home.html.
        </p>
        <p class="text-base">
          If you have questions or comments about your privacy rights, you may
          email us at hello@alphasofthub.com.
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          Account Information
        </h3>
        <p class="text-base">
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <li>Log in to your account settings and update your user account.</li>
        <li>Contact us using the contact information provided.</li>
        <p class="text-base">
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          Terms of Use and/or comply with applicable legal requirements.
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          Cookies and similar technologies:
        </h3>
        <p class="text-base">
          Most Web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove cookies and to
          reject cookies. If you choose to remove cookies or reject cookies,
          this could affect certain features or services of our Website. To
          opt-out of interest-based advertising by advertisers on our Website
          visit http://www.aboutads.info/choices/. For further information,
          please see our Cookie Notice:
          https://alphasofthub.com/index.php/cookie-policy/.
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          Opting out of email marketing:
        </h3>
        <p class="text-base">
          You can unsubscribe from our marketing email list at any time by
          clicking on the unsubscribe link in the emails that we send or by
          contacting us using the details provided below. You will then be
          removed from the marketing email list — however, we may still
          communicate with you, for example to send you service-related emails
          that are necessary for the administration and use of your account, to
          respond to service requests, or for other non-marketing purposes. To
          otherwise opt-out, you may:
        </p>
        <li>Access your account settings and update your preferences.</li>
        <li>Contact us using the contact information provided.</li>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          10. CONTROLS FOR DO-NOT-TRACK FEATURES
        </h3>
        <p class="text-base">
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track (“DNT”) feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </h3>
        <h3 class="title-font text-bold-600 mb-4 text-2xl">In Short:</h3>
        <p class="text-base">
          Yes, if you are a resident of California, you are granted specific
          rights regarding access to your personal information.
        </p>
        <p class="text-base">
          California Civil Code Section 1798.83, also known as the “Shine The
          Light” law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>
        <p class="text-base">
          If you are under 18 years of age, reside in California, and have a
          registered account with the Website, you have the right to request
          removal of unwanted data that you publicly post on the Website. To
          request removal of such data, please contact us using the contact
          information provided below, and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Website, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g. backups, etc.).
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          CCPA Privacy Notice
        </h3>
        <p class="text-base">
          The California Code of Regulations defines a “resident” as:
        </p>
        <li>
          every individual who is in the State of California for other than a
          temporary or transitory purpose and
        </li>
        <li>
          every individual who is domiciled in the State of California who is
          outside the State of California for a temporary or transitory purpose
        </li>
        <p class="text-base">
          All other individuals are defined as “non-residents.”
        </p>
        <p class="text-base">
          If this definition of “resident” applies to you, we must adhere to
          certain rights and obligations regarding your personal information.
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          What categories of personal information do we collect?
        </h3>
        <p class="text-base">
          We have collected the following categories of personal information in
          the past twelve (12) months:
        </p>

        <tr>
          <td>
            <h3 class="title-font text-bold-600 mb-4 text-2xl">Category</h3>
          </td>
          <td>
            <h3 class="title-font text-bold-600 mb-4 text-2xl">Examples</h3>
          </td>
          <td>
            <h3 class="title-font text-bold-600 mb-4 text-2xl">Collected</h3>
          </td>
        </tr>
        <tr>
          <td><p class="text-base">A. Identifiers</p></td>
          <td>
            <p class="text-base">
              Contact details, such as real name, alias, postal address,
              telephone or mobile contact number, unique personal identifier,
              online identifier, Internet Protocol address, email address and
              account name
            </p>
          </td>
          <td><p class="text-base">YES</p></td>
        </tr>
        <tr>
          <td>
            <p class="text-base">
              B. Personal information categories listed in the California
              Customer Records statute
            </p>
          </td>
          <td>
            <p class="text-base">
              Name, contact information, education, employment, employment
              history and financial information
            </p>
          </td>
          <td><p class="text-base">YES</p></td>
        </tr>
        <tr>
          <td>
            <p class="text-base">
              C. Protected classification characteristics under California or
              federal law
            </p>
          </td>
          <td><p class="text-base">Gender and date of birth</p></td>
          <td><p class="text-base">YES</p></td>
        </tr>
        <tr>
          <td><p class="text-base">D. Commercial information</p></td>
          <td>
            <p class="text-base">
              Transaction information, purchase history, financial details and
              payment information
            </p>
          </td>
          <td><p class="text-base">YES</p></td>
        </tr>
        <tr>
          <td><p class="text-base">E. Biometric information</p></td>
          <td><p class="text-base">Fingerprints and voiceprints</p></td>
          <td><p class="text-base">NO</p></td>
        </tr>
        <tr>
          <td>
            <p class="text-base">
              F. Internet or other similar network activity
            </p>
          </td>
          <td>
            <p class="text-base">
              Browsing history, search history, online behavior, interest data,
              and interactions with our and other websites, applications,
              systems and advertisements
            </p>
          </td>
          <td><p class="text-base">NO</p></td>
        </tr>
        <tr>
          <td><p class="text-base">G. Geolocation data</p></td>
          <td><p class="text-base">Device location</p></td>
          <td><p class="text-base">NO</p></td>
        </tr>
        <tr>
          <td>
            <p class="text-base">
              H. Audio, electronic, visual, thermal, olfactory, or similar
              information
            </p>
          </td>
          <td>
            <p class="text-base">
              Images and audio, video or call recordings created in connection
              with our business activities
            </p>
          </td>
          <td><p class="text-base">NO</p></td>
        </tr>
        <tr>
          <td>
            <p class="text-base">
              I. Professional or employment-related information
            </p>
          </td>
          <td>
            <p class="text-base">
              Business contact details in order to provide you our services at a
              business level, job title as well as work history and professional
              qualifications if you apply for a job with us
            </p>
          </td>
          <td><p class="text-base">NO</p></td>
        </tr>
        <tr>
          <td><p class="text-base">J. Education Information</p></td>
          <td>
            <p class="text-base">Student records and directory information</p>
          </td>
          <td><p class="text-base">NO</p></td>
        </tr>
        <tr>
          <td>
            <p class="text-base">
              K. Inferences drawn from other personal information
            </p>
          </td>
          <td>
            <p class="text-base">
              Inferences drawn from any of the collected personal information
              listed above to create a profile or summary about, for example, an
              individual’s preferences and characteristics
            </p>
          </td>
          <td><p class="text-base">NO</p></td>
        </tr>

        <p class="text-base">
          We may also collect other personal information outside of these
          categories instances where you interact with us in-person, online, or
          by phone or mail in the context of:
        </p>
        <li>Receiving help through our customer support channels;</li>
        <li>Participation in customer surveys or contests; and</li>
        <li>
          Facilitation in the delivery of our Services and to respond to your
          inquiries.
        </li>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          How do we use and share your personal information?
        </h3>
        <p class="text-base">
          More information about our data collection and sharing practices can
          be found in this privacy notice and our Cookie Notice:
          https://alphasofthub.com/index.php/cookie-policy/.
        </p>
        <p class="text-base">
          You may contact us by email at hello@alphasofthub.cp,, by visiting
          https://alphasofthub.com/index.php/contact-us/, or by referring to the
          contact details at the bottom of this document.
        </p>
        <p class="text-base">
          If you are using an authorized agent to exercise your right to opt-out
          we may deny a request if the authorized agent does not submit proof
          that they have been validly authorized to act on your behalf.
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          Will your information be shared with anyone else?
        </h3>
        <p class="text-base">
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider.
          Each service provider is a for-profit entity that processes the
          information on our behalf.
        </p>
        <p class="text-base">
          We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological
          development and demonstration. This is not considered to be “selling”
          of your personal data
        </p>
        <p class="text-base">
          ALPHASOFTHUB Private Limited has not disclosed or sold any personal
          information to third parties for a business or commercial purpose in
          the preceding 12 months. ALPHASOFTHUB Private Limited will not sell
          personal information in the future belonging to website visitors,
          users and other consumers.
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          Your rights with respect to your personal data
        </h3>
        <p class="text-base">
          Right to request deletion of the data – Request to delete
        </p>
        <p class="text-base">
          You can ask for the deletion of your personal information. If you ask
          us to delete your personal information, we will respect your request
          and delete your personal information, subject to certain exceptions
          provided by law, such as (but not limited to) the exercise by another
          consumer of his or her right to free speech, our compliance
          requirements resulting from a legal obligation or any processing that
          may be required to protect against illegal activities.
        </p>
        <p class="text-base">Right to be informed – Request to know</p>
        <p class="text-base">
          Depending on the circumstances, you have a right to know:
        </p>
        <li>whether we collect and use your personal information;</li>
        <li>the categories of personal information that we collect;</li>
        <li>
          the purposes for which the collected personal information is used;
        </li>
        <li>whether we sell your personal information to third parties;</li>
        <li>
          the categories of third parties to whom the personal information was
          sold or disclosed for a business purpose; and
        </li>
        <li>
          the business or commercial purpose for collecting or selling personal
          information.
        </li>
        <p class="text-base">
          In accordance with applicable law, we are not obligated to provide or
          delete consumer information that is de-identified in response to a
          consumer request or to re-identify individual data to verify a
          consumer request.
        </p>
        <p class="text-base">
          Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
          Rights
        </p>
        <p class="text-base">
          We will not discriminate against you if you exercise your privacy
          rights.
        </p>
        <p class="text-base">Verification process</p>
        <p class="text-base">
          Upon receiving your request, we will need to verify your identity to
          determine you are the same person about whom we have the information
          in our system. These verification efforts require us to ask you to
          provide information so that we can match it with information you have
          previously provided us. For instance, depending on the type of request
          you submit, we may ask you to provide certain information so that we
          can match the information you provide with the information we already
          have on file, or we may contact you through a communication method
          (e.g. phone or email) that you have previously provided to us. We may
          also use other verification methods as the circumstances dictate.
        </p>
        <p class="text-base">
          We will only use personal information provided in your request to
          verify your identity or authority to make the request. To the extent
          possible, we will avoid requesting additional information from you for
          the purposes of verification. If, however, we cannot verify your
          identity from the information already maintained by us, we may request
          that you provide additional information for the purposes of verifying
          your identity, and for security or fraud-prevention purposes. We will
          delete such additionally provided information as soon as we finish
          verifying you
        </p>
        <p class="text-base">Other privacy rights</p>
        <li>you may object to the processing of your personal data</li>
        <li>
          you may request correction of your personal data if it is incorrect or
          no longer relevant, or ask to restrict the processing of the data/li>
        </li>
        <li>
          you can designate an authorized agent to make a request under the CCPA
          on your behalf. We may deny a request from an authorized agent that
          does not submit proof that they have been validly authorized to act on
          your behalf in accordance with the CCPA.
        </li>
        <li>
          you may request to opt-out from future selling of your personal
          information to third parties. Upon receiving a request to opt-out, we
          will act upon the request as soon as feasibly possible, but no later
          than 15 days from the date of the request submission.
        </li>
        <p class="text-base">
          To exercise these rights, you can contact us by email at
          hello@alphasofthub.cp,, by visiting
          https://alphasofthub.com/index.php/contact-us/, or by referring to the
          contact details at the bottom of this document. If you have a
          complaint about how we handle your data, we would like to hear from
          you.
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          12. DO WE MAKE UPDATES TO THIS NOTICE?
        </h3>
        <h3 class="title-font text-bold-600 mb-4 text-2xl">In Short:</h3>
        <p class="text-base">
          Yes, we will update this notice as necessary to stay compliant with
          relevant laws.
        </p>
        <p class="text-base">
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated “Revised” date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </h3>
        <p class="text-base">
          If you have questions or comments about this notice, you may email us
          at hello@alphasosfthub.com or by post to:
        </p>
        <p class="text-base">ALPHASOFTHUB Private Limited</p>
        <p class="text-base">Rawalpindi Pakistn</p>
        <p class="text-base">Rawalpindi, Punjab46000</p>
        <p class="text-base">Pakistan</p>

        <h3 class="title-font text-bold-600 mb-4 text-2xl">
          14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </h3>
        <p class="text-base">
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it in some circumstances. To
          request to review, update, or delete your personal information, please
          visit: hello@alphasofthub.com. We will respond to your request within
          30 days.
        </p>
      </div>
    </div>
  </div>
</template>